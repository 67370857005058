import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';
import SelectedItemsList from './SelectedItemsList';
import '../DataVisualization.css';

function DataVisualization({
  data = [],
  headers = [],
  showChart = false,
  showFields = false,
  selectedDependent = [],
  setSelectedDependent,
  selectedIndependent = [],
  setSelectedIndependent,
  selectedDateColumn = [],
  setSelectedDateColumn,
}) {
  const [warningMessage, setWarningMessage] = useState('');
  const [updatedData, setUpdatedData] = useState([]);
  const [updatedHeaders, setUpdatedHeaders] = useState([]);
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showChartTransition, setShowChartTransition] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const desiredOrder = ['ID', 'PERIOD', 'ACTUAL', 'FORECAST'];

  const dateOptions = headers
    .filter((header) => header && (header.toUpperCase().includes('DATE') || header.toUpperCase().includes('PERIOD')))
    .map((header) => ({ label: header, value: header }));

  const nonDateOptions = headers
    .filter((header) => !(header && (header.toUpperCase().includes('DATE') || header.toUpperCase().includes('PERIOD'))))
    .map((header) => ({ label: header, value: header }));

  useEffect(() => {
    if (!data || !headers || data.length === 0 || headers.length === 0) {
      return;
    }

    setUpdatedData(data);
    setShowTable(false);
    setShowChartTransition(false);

    setTimeout(() => {
      setShowTable(true);
      if (showChart) {
        setShowChartTransition(true);
      }
    }, 100);

    const newHeaders = headers.includes('FORECAST')
      ? desiredOrder.filter((header) => headers.includes(header))
      : headers.filter((header) => header !== 'FORECAST');

    setUpdatedHeaders(newHeaders);

    if (originalHeaders.length === 0) {
      setOriginalHeaders(headers);
    }

    const aggregatedData = aggregateDataByMonth(data);
    setMonthlyData(aggregatedData);
  }, [data, headers, showChart]);

  const checkScreenSize = () => {
    const screenWidth = window.innerWidth;
    setIsSmallScreen(screenWidth <= 768);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleDependentChange = (selectedOptions) => {
    if (Array.isArray(selectedOptions) && selectedOptions.length > 1) {
      setWarningMessage('You can only select one dependent variable.');
      return;
    }

    const conflictItem = selectedOptions.find((option) =>
      selectedIndependent && Array.isArray(selectedIndependent) && selectedIndependent.some((ind) => ind.value === option.value)
    );

    if (conflictItem) {
      setWarningMessage(`${conflictItem.label} is already selected as an independent variable.`);
      return;
    }

    setSelectedDependent(selectedOptions);
    setWarningMessage('');
  };

  const handleIndependentChange = (selectedOptions) => {
    const conflictItem = selectedOptions.find((option) =>
      selectedDependent && Array.isArray(selectedDependent) && selectedDependent.some((dep) => dep.value === option.value)
    );

    if (conflictItem) {
      setWarningMessage(`${conflictItem.label} is already selected as a dependent variable.`);
      return;
    }

    setSelectedIndependent(selectedOptions);
    setWarningMessage('');
  };

  const handleDateColumnChange = (selectedOptions) => {
    console.log('Selected options before validation:', selectedOptions);

    // Handle the case when no options are selected
    if (!selectedOptions || selectedOptions.length === 0) {
        setSelectedDateColumn([]);
        setWarningMessage('');
        console.log('No date column selected');
        return;
    }

    // Enforce that only one date column can be selected at a time
    if (selectedOptions.length > 1) {
        setWarningMessage('You can only select one date column.');
        return;
    }

    // Continue with existing validation logic
    const selectedOption = selectedOptions[0]; // Since only one item can be selected, take the first item
    const sampleDates = data.map(entry => entry[selectedOption.value]).slice(0, 10); // Adjust slice for full dataset if needed

    // Validate that all dates are the first of the month in any of the valid formats
    const dateFormats = ['YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY'];
    const areDatesValid = sampleDates.every(date => {
        return dateFormats.some(format => {
            return moment(date, format, true).isValid() && moment(date, format).date() === 1;
        });
    });

    if (!areDatesValid) {
        setWarningMessage('All dates must be the first of the month in formats like YYYY-MM-DD, DD-MM-YYYY, or MM-DD-YYYY.');
        console.log('Invalid date column:', selectedOptions);
        return;
    }

    setSelectedDateColumn(selectedOptions);
    setWarningMessage('');
    console.log('Valid date column:', selectedOptions);
  };

  const containerHeight = window.innerHeight * 0.8;

  const aggregateDataByMonth = (data) => {
    const groupedData = data.reduce((acc, curr) => {
      const month = moment(curr.PERIOD).format('YYYY-MM');
      if (!acc[month]) {
        acc[month] = { PERIOD: month, ACTUAL: 0, FORECAST: 0, count: 0 };
      }
      acc[month].ACTUAL += curr.ACTUAL;
      acc[month].FORECAST += curr.FORECAST;
      acc[month].count += 1;
      return acc;
    }, {});

    return Object.values(groupedData).map((item) => ({
      PERIOD: item.PERIOD,
      ACTUAL: item.ACTUAL / item.count,
      FORECAST: item.FORECAST / item.count,
    }));
  };

  useEffect(() => {
    if (showChartTransition && monthlyData.length > 0) {
      renderChart();
    }
  }, [showChartTransition, monthlyData]);

  const renderChart = () => {
    const svg = d3.select('#chart-svg');
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 40, bottom: 120, left: 80 };

    const width = isSmallScreen
      ? document.querySelector('.container-wrapper')?.clientWidth - margin.left - margin.right || window.innerWidth - margin.left - margin.right
      : window.innerWidth * 0.6 - margin.left - margin.right;

    const height = containerHeight - margin.top - margin.bottom;

    // Handle potential missing data and enforce data continuity
    const allDates = monthlyData.map(d => moment(d.PERIOD).toDate());
    const x = d3.scaleTime()
      .domain(d3.extent(allDates))
      .range([0, width]);

    const y = d3.scaleLinear()
      .domain([
        d3.min(monthlyData, (d) => Math.min(d.ACTUAL, d.FORECAST)) * 1.1,
        d3.max(monthlyData, (d) => Math.max(d.ACTUAL, d.FORECAST)) * 1.1
      ])
      .nice()
      .range([height, 0]);

    const area = d3.area()
      .x((d) => x(moment(d.PERIOD).toDate()))
      .y0(height)
      .y1((d) => y(d.FORECAST));

    const lineACTUAL = d3.line()
      .x((d) => x(moment(d.PERIOD).toDate()))
      .y((d) => y(d.ACTUAL))
      .defined(d => !isNaN(d.ACTUAL));  // Filter out undefined or NaN values

    const lineFORECAST = d3.line()
      .x((d) => x(moment(d.PERIOD).toDate()))
      .y((d) => y(d.FORECAST))
      .defined(d => !isNaN(d.FORECAST));  // Filter out undefined or NaN values

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    g.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(6).tickSize(-height).tickFormat(''));

    g.append('g').attr('class', 'grid').call(d3.axisLeft(y).ticks(10).tickSize(-width).tickFormat(''));

    g.append('path')
      .datum(monthlyData)
      .attr('fill', 'none')
      .attr('stroke', '#6a51a3')
      .attr('stroke-width', 2)
      .attr('d', lineACTUAL)
      .attr('stroke-dasharray', function () {
        return this.getTotalLength();
      })
      .attr('stroke-dashoffset', function () {
        return this.getTotalLength();
      })
      .transition()
      .duration(2000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);

    g.append('path')
      .datum(monthlyData)
      .attr('fill', 'none')
      .attr('stroke', '#17becf')
      .attr('stroke-width', 2)
      .attr('d', lineFORECAST)
      .attr('stroke-dasharray', function () {
        return this.getTotalLength();
      })
      .attr('stroke-dashoffset', function () {
        return this.getTotalLength();
      })
      .transition()
      .duration(2000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);

    g.append('path')
      .datum(monthlyData)
      .attr('fill', 'rgba(23, 190, 207, 0.2)')
      .attr('d', area);

    svg.append('g')
      .attr('transform', `translate(${margin.left},${height + margin.top})`)
      .call(d3.axisBottom(x).ticks(6));

    svg.append('text')
      .attr('class', 'x-axis-label')
      .attr('x', width / 2 + margin.left)
      .attr('y', height + margin.top + 40)
      .style('text-anchor', 'middle')
      .style('font-size', '14px')
      .style('font-weight', 'normal')
      .text('PERIOD');

    svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .call(d3.axisLeft(y).ticks(10));

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', margin.left - 70)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .style('font-size', '14px')
      .style('font-weight', 'normal')
      .text(selectedDependent.length > 0 ? selectedDependent[0].label : 'Value');

    const legend = svg.append('g')
      .attr('transform', `translate(${width + margin.left - 50}, ${margin.top})`);

    legend.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', '#6a51a3');

    legend.append('text')
      .attr('x', 15)
      .attr('y', 10)
      .style('font-size', '12px')
      .style('alignment-baseline', 'middle')
      .text('Actual');

    legend.append('rect')
      .attr('x', 0)
      .attr('y', 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', '#17becf');

    legend.append('text')
      .attr('x', 15)
      .attr('y', 30)
      .style('font-size', '12px')
      .style('alignment-baseline', 'middle')
      .text('Forecast');
  };

  return (
    <section>
      <div className="data-visualization-container">
        <a name="DataVisualization"></a>
        <div className="content-wrapper">
          {showFields ? (
            <>
              {warningMessage && <p className="warning-message" style={{ color: 'red' }}>{warningMessage}</p>}
              <div className={`multi-select-container-horizontal ${isSmallScreen ? 'vertical' : ''}`}>
                <div className="multi-select-item">
                  <div className="multi-select-header">
                    <span className="multi-select-header-icon">📅</span>
                    <span className="multi-select-header-text">Select Date Column</span>
                  </div>
                  <MultiSelect
                    options={dateOptions}
                    value={selectedDateColumn}
                    onChange={handleDateColumnChange}
                    hasSelectAll={false} // User can only select one option
                    isMulti={false} // Ensure single selection only
                    overrideStrings={{
                      allItemsAreSelected: '', // Override to remove "All Items Selected"
                    }}
                  />
                  <SelectedItemsList items={selectedDateColumn} />
                </div>
                <div className="multi-select-item">
                  <div className="multi-select-header">
                    <span className="multi-select-header-icon">📊</span>
                    <span className="multi-select-header-text">Select Dependent Variable</span>
                  </div>
                  <MultiSelect
                    options={nonDateOptions}
                    value={selectedDependent}
                    onChange={handleDependentChange}
                    labelledBy="Select Dependent"
                    hasSelectAll={false} // Remove "Select All" option
                    overrideStrings={{
                      allItemsAreSelected: '', // Override to remove "All Items Selected"
                    }}
                  />
                  <SelectedItemsList items={selectedDependent} />
                </div>
                <div className="multi-select-item">
                  <div className="multi-select-header">
                    <span className="multi-select-header-icon">📈</span>
                    <span className="multi-select-header-text">Select Independent Variables</span>
                  </div>
                  <MultiSelect
                    options={nonDateOptions}
                    value={selectedIndependent}
                    onChange={handleIndependentChange}
                    labelledBy="Select Independent"
                    hasSelectAll={false} // Remove "Select All" option
                    overrideStrings={{
                      allItemsAreSelected: '', // Override to remove "All Items Selected"
                    }}
                  />
                  <SelectedItemsList items={selectedIndependent} />
                </div>
              </div>
            </>
          ) : updatedData.length === 0 ? (
            <p className="no-data-message">Upload data to explore</p>
          ) : (
            <div className="container-wrapper" style={{ height: containerHeight }}>
              {showChart ? (
                <div className={`chart-container ${showChartTransition ? 'show' : ''}`} style={{ height: '100%' }}>
                  <h3 style={{ textAlign: 'center', width: '27%', marginBottom: '10px', display: 'block' }}>Actual vs Forecast</h3>
                  <svg id="chart-svg" width={isSmallScreen ? '100%' : window.innerWidth} height={containerHeight}></svg>
                </div>
              ) : (
                <div className={`table-container ${showTable ? 'show' : ''}`} style={{ height: '100%', overflowY: 'auto' }}>
                  <table>
                    <thead>
                      <tr>
                        {updatedHeaders.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {updatedData.map((row, index) => (
                        <tr key={index}>
                          {updatedHeaders.map((header, colIndex) => (
                            <td key={colIndex}>
                              {header.toUpperCase() === 'PERIOD' ? moment(row[header]).format('YYYY-MM-DD') : row[header]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default DataVisualization;
