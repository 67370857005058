// SelectedItemsList.js
import React from 'react';

const SelectedItemsList = ({ items }) => {
  return (
    <div className="selected-items-list">
      {items.length === 0 ? (
        <p>No items selected</p>
      ) : (
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item.label}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectedItemsList;
