import React from 'react';
import '../App.css';
import '../Header.css';

function Header({ setShowWip }) {
  const handleScrollToSection = (event, sectionId) => {
    event.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="header-bar">
      <div className="button-container">
        <button className="header-button" onClick={(e) => handleScrollToSection(e, 'how-to')}>
          HOW-TO
        </button>
        <button className="header-button" onClick={(e) => { e.preventDefault(); setShowWip(true); }}>
          DOCUMENTATION
        </button>
        <button className="header-button" onClick={(e) => { e.preventDefault(); setShowWip(true); }}>
          SUBSCRIPTION
        </button>
      </div>
    </div>
  );
}

export default Header;
