// Sidebar.js

import React, { useState, useEffect } from 'react';
import '../Sidebar.css';
import DataVisualization from './DataVisualization';

function Sidebar({
  fileInputRef,
  handleFileChange,
  triggerFileInput,
  handleShowChart,
  handleProcessData,
  handleShowFields,
  handleExport,
  data,
  headers,
  showChart,
  showFields,
  selectedDependent,
  setSelectedDependent,
  selectedIndependent,
  setSelectedIndependent,
  selectedDateColumn,  // Pass selectedDateColumn as a prop
  setSelectedDateColumn,  // Pass setSelectedDateColumn as a prop
  setLoading,  // New prop to set the loading state
}) {
  const [activeDiv, setActiveDiv] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleClick = async (divIndex, action) => {
    setActiveDiv(divIndex);
    setLoading(true);  // Start the spinner before the action is triggered
    await action();  // Execute the action
    setLoading(false); // Stop the spinner after the action completes
  };

  const handleFileInputChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      if (fileExtension !== 'xlsx') {
        setErrorMessage('Please upload a valid .xlsx file.');
        e.target.value = ''; // Clear the file input
      } else {
        setErrorMessage(''); // Clear error message if file is valid
        await handleClick(1, () => handleFileChange(e));  // Trigger the file change with spinner
      }
    }
  };

  // Check if the screen is mobile or not
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <frcg-left role="complementary" aria-label="related content">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      
      {/* Div 1 */}
      <div
        className={`frcg-module ${activeDiv === 1 ? 'active' : ''}`}
        onClick={() => handleClick(1, triggerFileInput)}
      >
        <div className="frcg-number">1</div>
        <div className="frcg-content">
          <div className="frcg-upload-text">Import</div>
        </div>
        <div className="frcg-logo">
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx"
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
          />
          <button className="frcg-upload-btn interactive-btn">
            <img src={`${process.env.PUBLIC_URL}/frcg_excel.svg`} alt="Upload" />
          </button>
        </div>
      </div>
      {isMobile && activeDiv === 1 && (
        <div className="data-visualization-mobile">
          <DataVisualization 
            data={data}
            headers={headers}
            showChart={showChart}
            showFields={showFields}
            selectedDependent={selectedDependent}
            setSelectedDependent={setSelectedDependent}
            selectedIndependent={selectedIndependent}
            setSelectedIndependent={setSelectedIndependent}
            selectedDateColumn={selectedDateColumn}  // Pass the selected date column to DataVisualization
            setSelectedDateColumn={setSelectedDateColumn}  // Pass the setter for date column
          />
        </div>
      )}

      {/* Div 2 */}
      <div
        className={`frcg-module ${activeDiv === 2 ? 'active' : ''}`}
        onClick={() => handleClick(2, handleShowFields)}
      >
        <div className="frcg-number">2</div>
        <div className="frcg-content">
          <div className="frcg-upload-text">Fields to predict</div>
        </div>
        <div className="frcg-logo">
          <button className="frcg-upload-btn interactive-btn">
            <img src={`${process.env.PUBLIC_URL}/frcg_variable_selection.svg`} alt="Fields to Predict" />
          </button>
        </div>
      </div>
      {isMobile && activeDiv === 2 && (
        <div className="data-visualization-mobile">
          <DataVisualization 
            data={data}
            headers={headers}
            showChart={showChart}
            showFields={showFields}
            selectedDependent={selectedDependent}
            setSelectedDependent={setSelectedDependent}
            selectedIndependent={selectedIndependent}
            setSelectedIndependent={setSelectedIndependent}
            selectedDateColumn={selectedDateColumn}  // Pass the selected date column to DataVisualization
            setSelectedDateColumn={setSelectedDateColumn}  // Pass the setter for date column
          />
        </div>
      )}

      {/* Div 3 */}
      <div
        className={`frcg-module ${activeDiv === 3 ? 'active' : ''}`}
        onClick={() => handleClick(3, handleProcessData)}
      >
        <div className="frcg-number">3</div>
        <div className="frcg-content">
          <div className="frcg-upload-text">Predict</div>
        </div>
        <div className="frcg-logo">
          <button className="frcg-upload-btn interactive-btn">
            <img src={`${process.env.PUBLIC_URL}/frcg_predict.svg`} alt="Predict" />
          </button>
        </div>
      </div>
      {isMobile && activeDiv === 3 && (
        <div className="data-visualization-mobile">
          <DataVisualization 
            data={data}
            headers={headers}
            showChart={showChart}
            showFields={showFields}
            selectedDependent={selectedDependent}
            setSelectedDependent={setSelectedDependent}
            selectedIndependent={selectedIndependent}
            setSelectedIndependent={setSelectedIndependent}
            selectedDateColumn={selectedDateColumn}  // Pass the selected date column to DataVisualization
            setSelectedDateColumn={setSelectedDateColumn}  // Pass the setter for date column
          />
        </div>
      )}

      {/* Div 4 */}
      <div
        className={`frcg-module ${activeDiv === 4 ? 'active' : ''}`}
        onClick={() => handleClick(4, handleShowChart)}
      >
        <div className="frcg-number">4</div>
        <div className="frcg-content">
          <div className="frcg-upload-text">Plot</div>
        </div>
        <div className="frcg-logo">
          <button className="frcg-upload-btn interactive-btn">
            <img src={`${process.env.PUBLIC_URL}/frcg_trend.svg`} alt="Plot" />
          </button>
        </div>
      </div>
      {isMobile && activeDiv === 4 && (
        <div className="data-visualization-mobile">
          <DataVisualization 
            data={data}
            headers={headers}
            showChart={showChart}
            showFields={showFields}
            selectedDependent={selectedDependent}
            setSelectedDependent={setSelectedDependent}
            selectedIndependent={selectedIndependent}
            setSelectedIndependent={setSelectedIndependent}
            selectedDateColumn={selectedDateColumn}  // Pass the selected date column to DataVisualization
            setSelectedDateColumn={setSelectedDateColumn}  // Pass the setter for date column
          />
        </div>
      )}

      {/* Div 5 */}
      <div
        className={`frcg-module ${activeDiv === 5 ? 'active' : ''}`}
        onClick={() => handleClick(5, handleExport)}
      >
        <div className="frcg-number">5</div>
        <div className="frcg-content">
          <div className="frcg-upload-text">Export</div>
        </div>
        <div className="frcg-logo">
          <button className="frcg-upload-btn interactive-btn">
            <img src={`${process.env.PUBLIC_URL}/frcg_export.svg`} alt="Export" />
          </button>
        </div>
      </div>
      {isMobile && activeDiv === 5 && (
        <div className="data-visualization-mobile">
          <DataVisualization 
            data={data}
            headers={headers}
            showChart={showChart}
            showFields={showFields}
            selectedDependent={selectedDependent}
            setSelectedDependent={setSelectedDependent}
            selectedIndependent={selectedIndependent}
            setSelectedIndependent={setSelectedIndependent}
            selectedDateColumn={selectedDateColumn}  // Pass the selected date column to DataVisualization
            setSelectedDateColumn={setSelectedDateColumn}  // Pass the setter for date column
          />
        </div>
      )}
    </frcg-left>
  );
}

export default Sidebar;
