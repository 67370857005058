import React, { useState } from 'react';
import '../HowTo.css';

function HowTo() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="how-to" className="how-to-section">
      <h3>HOW-TO</h3>
      <div className="accordion">
        <div className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === 0 ? 'active' : ''}`}
            onClick={() => toggleAccordion(0)}
          >
            Step 1: Import Your Data
            <span className="accordion-icon">
              {activeIndex === 0 ? '-' : '+'}
            </span>
          </div>
          {activeIndex === 0 && (
            <div className="accordion-content">
              Click the <strong>IMPORT</strong> button and upload your Excel file. This will load your data into the tool so you can start the analysis process.
              <br /><br />
              Use the attached <a href={`${process.env.PUBLIC_URL}/Sample_Dummy_Data_V1.xlsx`} download="Sample_Dummy_Data_V1.xlsx" className="sample-dataset-link">sample dataset</a> as an example to get started.
            </div>
          )}
        </div>

        <div className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === 1 ? 'active' : ''}`}
            onClick={() => toggleAccordion(1)}
          >
            Step 2: Select What You Want to Predict
            <span className="accordion-icon">
              {activeIndex === 1 ? '-' : '+'}
            </span>
          </div>
          {activeIndex === 1 && (
            <div className="accordion-content">
              Next, click the <strong>FIELDS TO PREDICT</strong> button. Here, you'll select what you want to predict and the information that might influence it.
            </div>
          )}
        </div>

        <div className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === 2 ? 'active' : ''}`}
            onClick={() => toggleAccordion(2)}
          >
            Step 3: Predict
            <span className="accordion-icon">
              {activeIndex === 2 ? '-' : '+'}
            </span>
          </div>
          {activeIndex === 2 && (
            <div className="accordion-content">
              After selecting your fields, click the <strong>PREDICT</strong> button. The tool will create predictions based on your data, with no need for complex calculations on your part.
            </div>
          )}
        </div>

        <div className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === 3 ? 'active' : ''}`}
            onClick={() => toggleAccordion(3)}
          >
            Step 4: Plot Your Results
            <span className="accordion-icon">
              {activeIndex === 3 ? '-' : '+'}
            </span>
          </div>
          {activeIndex === 3 && (
            <div className="accordion-content">
              Click the <strong>PLOT</strong> button to generate a chart showing your predictions. This visual representation will help you easily understand the outcome.
            </div>
          )}
        </div>

        <div className="accordion-item">
          <div
            className={`accordion-header ${activeIndex === 4 ? 'active' : ''}`}
            onClick={() => toggleAccordion(4)}
          >
            Step 5: Export Your Results
            <span className="accordion-icon">
              {activeIndex === 4 ? '-' : '+'}
            </span>
          </div>
          {activeIndex === 4 && (
            <div className="accordion-content">
              Finally, click the <strong>EXPORT</strong> button to download your results in an Excel file, making it easy to share or review your data later.
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default HowTo;
