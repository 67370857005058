import React from 'react';
import '../Wip.css'; // A new WIP specific CSS

const Wip = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="wip-overlay">
      <div className="wip-left">
        <div className="wip-content">
          <h1>Work <span className="highlight">in</span> Progress</h1>
          <p>Stay tuned for updates!</p>
          <button className="wip-close-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Wip;
