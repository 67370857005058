// Spinner.js
import React from 'react';
import '../Spinner.css'; // Ensure you have appropriate styles for the spinner

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default Spinner;
