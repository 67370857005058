import React from 'react';
import HowTo from './HowTo';
import DataVisualization from './DataVisualization';

function MainContent({
  data,
  headers,
  showChart,
  showFields,
  selectedDependent,
  setSelectedDependent,
  selectedIndependent,
  setSelectedIndependent,
  selectedDateColumn, // Add selectedDateColumn prop
  setSelectedDateColumn, // Add setSelectedDateColumn prop
}) {
  const isMobile = window.innerWidth <= 768;

  return (
    <frcg-right role="frcg-right" aria-label="primary content">
      {!isMobile && (
        <DataVisualization
          data={data}
          headers={headers}
          showChart={showChart}
          showFields={showFields}
          selectedDependent={selectedDependent}
          setSelectedDependent={setSelectedDependent}
          selectedIndependent={selectedIndependent}
          setSelectedIndependent={setSelectedIndependent}
          selectedDateColumn={selectedDateColumn}  // Pass selectedDateColumn to DataVisualization
          setSelectedDateColumn={setSelectedDateColumn}  // Pass setSelectedDateColumn to DataVisualization
        />
      )}
      <HowTo />
    </frcg-right>
  );
}

export default MainContent;
