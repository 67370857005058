import React from 'react';
import '../OverlayPage.css';

const OverlayPage = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="overlay">
      <div className="overlay-left">
        <div className="overlay-content">
          <h1>Predict your <span className="highlight">sales/data</span> for free in minutes.</h1>
          <p>Our free online prediction tool is here to help you easily create prediction in minutes. No analytical or technical skills are needed.</p>
          <button className="close-button" onClick={onClose}>Let's Predict</button>
        </div>
      </div>
      <div className="overlay-right">
        <div className="overlay-right-content">
          <div className="logo">PREDICT<span style={{ color: 'orangered' }}>X</span><span style={{ color: '#228B22' }}>Y</span></div>
          <div className="step-text">What's next?</div>
          <div className="main-text">
            Your future insights are just a click away.
          </div>
          <div className="progress-bar"></div>
        </div>
      </div>
    </div>
  );
};

export default OverlayPage;
